import type { ApproxLocation } from "@ddr/models";
import { getCurrentLocation } from "@ddr/utils";
import { handleError } from "./error";

export const getCurrentLocationSafe =
  async (): Promise<ApproxLocation | null> => {
    try {
      return await getCurrentLocation();
    } catch (err) {
      handleError(
        err,
        "Failed to get your location",
        "Please enter it manually.",
      );
      return null;
    }
  };
