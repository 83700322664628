export const fetchJson = async <T = unknown>(
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<T> => {
  const res = await fetch(input, init);
  if (!res.ok) {
    throw new Error(`HTTP Error [${res.status}] ${res.statusText}`);
  }
  return (await res.json()) as T;
};
