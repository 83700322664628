import React, { useEffect, useRef } from "react";
import {
  MAPS_API_KEY,
  ONE_RESULT_OFFSET,
  PUBLIC_MAP_STYLED_ID,
} from "@ddr/utils";
import {
  GoogleMap,
  useLoadScript,
  LoadScriptProps,
  MarkerF,
} from "@react-google-maps/api";
import { ErrorBox } from "./error-box";

// Not sure why I don't need to add geocoding here
const libraries: LoadScriptProps["libraries"] = ["places"];

const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

const defaultOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
};

export type Point = { lat: number; lng: number };

export interface ProfileMarker {
  position: Point;
  id: string;
}

interface MapProps {
  center?: {
    lat: number;
    lng: number;
  };
  apiKey?: string;
  containerStyle?: React.CSSProperties;
  zoom?: number;
  options?: google.maps.MapOptions;
  onMarkerClick?: (id: string) => void;
  markers: ProfileMarker[];
  iconUrl: string;
  userHasClicked: boolean;
}

export const MarkerMap: React.FC<MapProps> = ({
  center,
  apiKey = MAPS_API_KEY,
  containerStyle = mapContainerStyle,
  zoom = 15,
  options = defaultOptions,
  onMarkerClick,
  markers,
  iconUrl,
  userHasClicked,
}) => {
  const mapId = options.mapId ?? PUBLIC_MAP_STYLED_ID;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    mapIds: [mapId],
    libraries,
  });

  // Get map ref
  const mapRef = useRef<google.maps.Map | null>(null);
  const handleMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  // Set map bounds to fit markers
  // https://gist.github.com/ericchen/5933441
  // https://developers.google.com/maps/documentation/javascript/reference/map#Map.fitBounds
  useEffect(() => {
    // Don't zoom on initial load
    if (!userHasClicked) return;

    // We want to zoom to see details of results, or zoom out to see all results
    // We don't want to zoom in for no reason, so don't zoom if there are no markers
    if (!mapRef.current || !markers.length) return;

    const latLngList = markers.map((marker) => marker.position);
    if (latLngList.length === 1 && latLngList[0]) {
      // Add fake markers to bounds to prevent zooming in too much
      const realMarker = latLngList[0];
      latLngList.push({
        lat: realMarker.lat + ONE_RESULT_OFFSET,
        lng: realMarker.lng + ONE_RESULT_OFFSET,
      });
      latLngList.push({
        lat: realMarker.lat - ONE_RESULT_OFFSET,
        lng: realMarker.lng - ONE_RESULT_OFFSET,
      });
    }

    // Create a new viewpoint bound
    const bounds = new google.maps.LatLngBounds();
    for (const latLng of latLngList) {
      // And increase the bounds to take this point
      bounds.extend(latLng);
    }

    mapRef.current.fitBounds(bounds);
  }, [markers]);

  // Should be caught by parent error boundary
  if (loadError)
    return <ErrorBox title="Sorry, maps are not loading." description="" />;
  if (!isLoaded || !center)
    return (
      // Not always an error, but the same box works
      <ErrorBox>
        <p>Loading maps...</p>
      </ErrorBox>
    );

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={zoom}
      center={center}
      options={{ ...options, mapId }}
      onLoad={handleMapLoad}
    >
      {markers.map((marker) => {
        return (
          <MarkerF
            key={marker.id}
            position={marker.position}
            onClick={() => onMarkerClick && onMarkerClick(marker.id)}
            icon={iconUrl}
          ></MarkerF>
        );
      })}
    </GoogleMap>
  );
};
