import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://26fc90d7c3681ba580bc9a4a505346a5@o423426.ingest.us.sentry.io/4507945506504704",
  integrations: [],

  // TODO: use react 19 hooks shown here to report all errors (within react root) uncaught by error boundary
  // Should be minimial errors since I can use useState hack to catch everything with error boundary
  // https://docs.sentry.io/platforms/javascript/guides/react/
  // * report unhandled inside my widget
  // * ignore global unhandled

  beforeSend: (event, _hint) => {
    // Sentry listens to all errors on the window and I'm getting spammed with all the Shopify errors
    // For now I won't report any unhandled exceptions and will only report from handler fn or error boundary
    if (event.tags?.appName) return event;
    return null;
  },
});
