import { toast } from "@ddr/ui";
import * as Sentry from "@sentry/react";

export const defaultSentryTags = { appName: "widgets" };

/** Report error and show toast if message is defined */
export const handleError = (
  err: unknown,
  message?: string,
  description?: string,
) => {
  if (message) {
    toast({ title: message, description, variant: "destructive" });
  }
  Sentry.captureException(err, { tags: { ...defaultSentryTags } });
};
