import type { Profile, ProfileSite, Radius } from "@ddr/models";
import { Button, Dialog } from "@ddr/ui";
import { type Point, type ProfileMarker, MarkerMap } from "@ddr/ui/src/map";
import { useCallback, useEffect, useState } from "react";
import ProfileListItem from "../components/profile-list-item";
import ProfileDetailView from "../components/profile-detail-view";
import { API_URL, BG_DARK, NEXT_URL, USE_MOCK } from "../config";
import LocationSearch from "@ddr/ui/src/location-search";
import ContactDialog from "../components/contact-dialog";
import { getConnections, getConnectionsMap } from "../data/connections";
import { Crosshair } from "lucide-react";
import { ErrorBox } from "@ddr/ui";
import { getCurrentLocationSafe } from "../utils/geo";
import { fetchJson } from "../utils/fetch";
import { handleError } from "../utils/error";
import { ErrorBoundary } from "../components/error-boundary";

// Only one site (at a time) is ever relevant to this page
type ProfileFlat = Profile & { site: ProfileSite };

const defaultZoom = 9.4;

const MAP_RADIUS_DEFAULT = 75;
const radiusOptions = [
  { value: "all", label: "Show All" },
  { value: "75", label: "75 Mi" },
  { value: "150", label: "150 Mi" },
  { value: "500", label: "500 Mi" },
];

export default function PilotMap() {
  const [ipLocation, setIpLocation] = useState<Point>();
  const [inputLocation, setInputLocation] = useState<Point>();
  const [radius, setRadius] = useState<Radius>(MAP_RADIUS_DEFAULT);
  const [profiles, setProfiles] = useState<ProfileFlat[]>([]);
  const [profileMarkers, setProfileMarkers] = useState<ProfileMarker[]>([]);
  const [detailViewOpen, setDetailViewOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<ProfileFlat>();
  // Usefule if we want to set zoom from parent component
  // const [zoom, setZoom] = useState(defaultZoom);
  const center = inputLocation ?? ipLocation;
  const [userHasClicked, setUserHasClicked] = useState(false);
  // const [error, setError] = useState<string | null>(null);

  const { activeConnections } = getConnections();
  const connectionsMap = getConnectionsMap(activeConnections);

  useEffect(() => {
    if (!center || !radius) return;
    fetchJson<Profile[]>(
      `${API_URL}/profiles?lat=${center.lat}&lng=${center.lng}&radius=${radius}&mock=${USE_MOCK}`,
    )
      .then((data) => {
        setProfiles(data.map((p) => ({ ...p, site: p.sites[0] })));
        const markers = data.map<ProfileMarker>((p) => ({
          // Pilots without public location are filterd out in the backend so we can assume it's always there
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          position: p.publicLocation!,
          id: p.account_handle,
        }));
        setProfileMarkers(markers);
      })
      .catch((err) => {
        handleError(err, "Failed to load profiles", "Please try again.");
      });
  }, [center, radius]);

  useEffect(() => {
    void getCurrentLocationSafe().then((l) => {
      if (!l) return;
      setIpLocation({ lat: Number(l.latitude), lng: Number(l.longitude) });
    });
  }, []);

  const handleCrosshairClick = useCallback(async () => {
    setUserHasClicked(true);
    const loc = await getCurrentLocationSafe();
    if (!loc) return;
    setIpLocation({ lat: Number(loc.latitude), lng: Number(loc.longitude) });
    setInputLocation(undefined);
    setRadius(MAP_RADIUS_DEFAULT);
  }, []);

  const handleMarkerClick = useCallback(
    (profileId: string) => {
      const profile = profiles.find((p) => p.account_handle === profileId);
      setSelectedProfile(profile);
      setDetailViewOpen(true);
    },
    [profiles],
  );

  const handleRadius = useCallback((radiusInput: string) => {
    const radius: Radius = radiusInput === "all" ? "all" : Number(radiusInput);
    setUserHasClicked(true);
    setRadius(radius);
    if (radius === "all") {
      // Center of the Us
      setInputLocation({ lat: 39.8, lng: -98.55 });
    }
  }, []);

  const handleLocationSearch = useCallback((location: Point) => {
    // Popup won't close if we use a fn directly instead of useCallback
    setUserHasClicked(true);
    setInputLocation(location);
    setRadius(MAP_RADIUS_DEFAULT);
  }, []);

  const profilesList = profiles.map((p) => {
    const site = p.sites.find((s) => s.site === "DEER");
    if (!site) return null;
    return (
      <ProfileListItem
        key={p.id}
        profile={p}
        existingConnection={connectionsMap.has(p.account_handle)}
        onViewMoreClick={() => {
          setSelectedProfile(p);
          setDetailViewOpen(true);
        }}
        onContactPilotClick={() => {
          setSelectedProfile(p);
          setContactModalOpen(true);
        }}
      />
    );
  });

  const mapErrorFallback = (
    <ErrorBox
      title="Sorry, the map is not working at the moment."
      description="You may be still able to find pilots using the list. Our team has been informed of the issue and will fix it soon."
    />
  );

  return (
    <div
      className={`${BG_DARK} flex min-h-[400px] justify-center p-2 md:px-20 md:py-10`}
    >
      <div className="bg-secondary flex max-w-[1600px] basis-[1600px] flex-wrap-reverse gap-1 overflow-hidden rounded-lg">
        <Dialog open={contactModalOpen} onOpenChange={setContactModalOpen}>
          {selectedProfile ? <ContactDialog profile={selectedProfile} /> : null}
        </Dialog>

        <div className="h-[600px] grow basis-[400px] overflow-y-scroll p-3 md:h-[800px] xl:h-[900px]">
          {detailViewOpen && selectedProfile ? (
            <ProfileDetailView
              profile={selectedProfile}
              existingConnection={connectionsMap.has(
                selectedProfile.account_handle,
              )}
              onClose={() => {
                setDetailViewOpen(false);
                setSelectedProfile(undefined);
              }}
              onContactPilotClick={() => {
                setContactModalOpen(true);
              }}
            />
          ) : (
            <>
              <nav className="mb-5">
                <div className="mb-2.5 flex items-center justify-between gap-3">
                  <LocationSearch
                    onSelect={handleLocationSearch}
                    className="basis-full"
                  />

                  <Button
                    variant="ghost"
                    className="p-0"
                    onClick={handleCrosshairClick}
                  >
                    <Crosshair />
                  </Button>
                </div>

                <div className="flex flex-wrap items-center gap-3">
                  {radiusOptions.map((option) => (
                    <Button
                      key={option.value}
                      onClick={() => handleRadius(option.value)}
                      size="sm"
                      // double equals is intentional (number == string)
                      variant={radius == option.value ? "default" : "outline"}
                    >
                      {option.label}
                    </Button>
                  ))}

                  {/* Only in shopify, select either throws an error on render or doesn't allow user to select any items */}
                  {/* <Select onValueChange={handleRadius} value={radius.toString()}>
                  <SelectTrigger className="shrink-0 basis-[95px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="25">25 Mi</SelectItem>
                    <SelectItem value="50">50 Mi</SelectItem>
                    <SelectItem value="75">75 Mi</SelectItem>
                    <SelectItem value="100">100 Mi</SelectItem>
                    <SelectItem value="150">150 Mi</SelectItem>
                    <SelectItem value="500">500 Mi</SelectItem>
                    <SelectItem value="all">All</SelectItem>
                  </SelectContent>
                </Select> */}
                </div>
              </nav>

              <ul>
                {profilesList.length ? (
                  profilesList
                ) : (
                  <div className="mt-10 flex justify-center">
                    <p className="text-muted-foreground text-xl">
                      No pilots found
                    </p>
                  </div>
                )}
              </ul>
            </>
          )}
        </div>

        <div className="h-[300px] grow basis-[800px] md:h-[500px] lg:h-[800px] xl:h-[900px]">
          <ErrorBoundary fallback={mapErrorFallback}>
            <MarkerMap
              center={center}
              userHasClicked={userHasClicked}
              containerStyle={{
                width: "100%",
                height: "100%",
              }}
              zoom={defaultZoom}
              onMarkerClick={handleMarkerClick}
              markers={profileMarkers}
              iconUrl={`${NEXT_URL}/map-icon.svg`}
            ></MarkerMap>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}
